import { Plugins, CameraResultType, Capacitor } from "@capacitor/core";
const { Camera } = Plugins;

let imgcounter = 0;

const dataURLtoFile = (dataurl: string, key: string) => {
	const arr = dataurl.split(',') as any
	const mime = arr[0].match(/:(.*?);/)[1]
	const subMime = mime.split('/')[1]
	const bstr = atob(arr[1])
	let n = bstr.length
	const u8arr = new Uint8Array(n)
	while (n) {
		u8arr[n - 1] = bstr.charCodeAt(n - 1)
		n -= 1 // to make eslint happy
	}
	return new File([u8arr], key + '.' + subMime, { type: mime })
}

async function capacitorGetPhoto() {
	try {
		let image: any;
		try {
			image = await Camera.getPhoto({
				quality: 90,
				allowEditing: true,
				width: 1920,
				height: 1920,
				preserveAspectRatio: true,
				resultType: CameraResultType.DataUrl,
			});
		} catch (e) {
			return false;
		}
		if (image === false)
			return false;
		else
			return dataURLtoFile((image as any).dataUrl, 'img-' + ++imgcounter);
	} catch (e) {
		return false;
	}

}   
    
async function nativeGetPhoto(video?: any) {
	
	return await new Promise(function(resolve) {

		const oldUpload = document.getElementById('cyanFileUpload');
		if (oldUpload) oldUpload.remove;
		const body = document.querySelector('body');
		if (!body) return;

		const upload = document.createElement('input');
		upload.setAttribute('type','file');
		upload.setAttribute('accept',video ? 'video/*' :'image/*');
		upload.setAttribute('id','cyanFileUpload');
		upload.setAttribute('style','display: none');

		upload.addEventListener('change', function() { resolve(upload); })

		body.appendChild(upload);

		upload.click();
		
		}).then(function(upload: any) {
			if (!upload || !upload.files || upload.files.length == 0) {
				return false;
			}
			return upload.files[0];
		})

}



export default async function(video?: any) {
	if (Capacitor.isNative && !video)
		return capacitorGetPhoto();
	else
		return nativeGetPhoto(video);

}